<template>
  <div
    v-loading.fullscreen.lock="loaderStore.visible"
    class="loader-overlay"
  />
</template>

<script lang="ts" setup>
import { useLoaderStore } from "~/stores/loader";

const loaderStore = useLoaderStore();
</script>
