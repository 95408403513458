const StaffMemberList = () => import("~/pages/staffmember/list.vue");
const UserDetail = () => import("~/pages/user/detail.vue");
const UserList = () => import("~/pages/user/list.vue");
const UsergroupDetail = () => import("~/pages/usergroup/detail.vue");
const UsergroupList = () => import("~/pages/usergroup/list.vue");

export default {
  routes: [
    {
      path: "/user/list",
      name: "user_list",
      component: UserList,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["user.read"]
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/user/detail/:id",
      component: UserDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["user.read"]
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/user/detail/:id/edit",
      component: UserDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["user.write"],
        crudMode: "update"
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/usergroup/list",
      component: UsergroupList,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["usergroup.read"],
        crudMode: "read",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/usergroup/detail/:id",
      component: UsergroupDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["usergroup.read"],
        crudMode: "read",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/usergroup/detail/:id/edit",
      component: UsergroupDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["usergroup.write"],
        crudMode: "update",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/usergroup/access/:id",
      component: UsergroupDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["usergroup.read"],
        contentMode: "access",
        crudMode: "read",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/usergroup/access/:id/edit",
      component: UsergroupDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["usergroup.write"],
        contentMode: "access",
        crudMode: "update",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/staffmember/list",
      component: StaffMemberList,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["staffsubrolemember.read"],
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
  ]
};
