import { useCustomFetch } from "~/hooks/useCustomFetch";

const usergroup_workspaces = "/api/access_core/usergroup_workspace/";
const usergroup_workspaces_update =
  "/api/access_core/usergroup_workspace/bulkupdate/";
const usergroup_workspaces_create =
  "/api/access_core/usergroup_workspace/bulkcreate/";

export default () => ({
  get_access({ id }) {
    const url = `${usergroup_workspaces}${id}/from_usergroup/?form`;
    return useCustomFetch(url);
  },
  update_access({ data }) {
    return useCustomFetch(usergroup_workspaces_update, {
      method: "PATCH",
      body: data,
      watch: false,
    });
  },
  create_access({ data }) {
    return useCustomFetch(usergroup_workspaces_create, {
      method: "POST",
      body: data,
      watch: false,
    });
  },
});
