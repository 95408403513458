import { $fetch } from "ofetch";
import { getBaseURL } from "~/utils";

export default () => ({
  async get() {
    return await $fetch(getBaseURL() + "/api/config/", {
      lazy: true,
      server: false,
    });
  },
});
