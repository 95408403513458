import { useCustomFetch } from "~/hooks/useCustomFetch";

const issues_url = "/api/device_core/issue/";
const activity_url = "/api/activity_core/problem/";

export default () => ({
  get(id) {
    return useCustomFetch(`${issues_url}${id}/`);
  },
  get_problem(id) {
    return useCustomFetch(`${activity_url}${id}/`);
  },
  update(id, data) {
    return useCustomFetch(`${issues_url}${id}/`, {
      method: "PATCH",
      body: data,
    });
  },
  update_problem(id, data) {
    return useCustomFetch(`${activity_url}${id}/`,{
      method: "PATCH",
        body: data,
    });
  },
  activities(id, page = 1) {
    let url = `${issues_url}${id}/activities/`;
    if (page > 1) {
      url += `?page=${page}`;
    }
    return useCustomFetch(url);
  },
});
