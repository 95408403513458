import moment from "moment";
import { toRaw } from "vue";
import { useCustomFetch } from "~/hooks/useCustomFetch";
import { useRuntimeConfig } from "#app";

export function getBaseURL() {
  const config = useRuntimeConfig();
  if (config.public.API_BASE_URL) {
    return config.public.API_BASE_URL;
  } else {
    return "https://" + getTenantName() + "-api.toolsquare.io";
  }
}

export function getTenantName() {
  if (process.client) {
    const location = window.location;

    let tlsqr_domain = location.host.indexOf(".toolsquare.io");
    if (tlsqr_domain > 0) {
      return location.host.substring(0, tlsqr_domain);
    } else if (location.host.indexOf("localhost") !== false) {
      return "localhost";
    } else {
      throw new Error("No tenant name found in url: " + location.host);
    }
  }
}

export const jwt_tokens = {
  tenant_name: getTenantName(),
  get access_token() {
    return localStorage.getItem(`${this.tenant_name}_access_token`);
  },
  get refresh_token() {
    return localStorage.getItem(`${this.tenant_name}_refresh_token`);
  },
  set access_token(token) {
    localStorage.setItem(`${this.tenant_name}_access_token`, token);
  },
  set refresh_token(token) {
    localStorage.setItem(`${this.tenant_name}_refresh_token`, token);
  },
  get su_access_token() {
    return localStorage.getItem(`su_${this.tenant_name}_access_token`);
  },
  get su_refresh_token() {
    return localStorage.getItem(`su_${this.tenant_name}_refresh_token`);
  },
  set su_access_token(token) {
    localStorage.setItem(`su_${this.tenant_name}_access_token`, token);
  },
  set su_refresh_token(token) {
    localStorage.setItem(`su_${this.tenant_name}_refresh_token`, token);
  },
  su_login: function () {
    localStorage.setItem(
      `su_${this.tenant_name}_refresh_token`,
      this.refresh_token,
    );
    localStorage.setItem(
      `su_${this.tenant_name}_access_token`,
      this.access_token,
    );
  },
  su_logout: function () {
    const su_access_token = localStorage.getItem(
      `su_${this.tenant_name}_access_token`,
    );
    if (su_access_token) {
      localStorage.setItem(`${this.tenant_name}_access_token`, su_access_token);
    }
    const su_refresh_token = localStorage.getItem(
      `su_${this.tenant_name}_refresh_token`,
    );
    if (su_refresh_token) {
      localStorage.setItem(
        `${this.tenant_name}_refresh_token`,
        su_refresh_token,
      );
    }

    // remove su tokens
    localStorage.removeItem(`su_${this.tenant_name}_access_token`);
    localStorage.removeItem(`su_${this.tenant_name}_refresh_token`);
  },
  remove: function () {
    localStorage.removeItem(`${this.tenant_name}_access_token`);
    localStorage.removeItem(`${this.tenant_name}_refresh_token`);
    localStorage.removeItem(`su_${this.tenant_name}_access_token`);
    localStorage.removeItem(`su_${this.tenant_name}_refresh_token`);
  },
};

export function getDateTimeFromString(
  dt,
  fmt = "YYYY-MM-DDTHH:mm:ss",
  fmt2 = "DD-MM-YYYY HH:mm",
) {
  return moment(dt, fmt).format(fmt2);
}

export function getTime(dt, fmt = "HH:mm") {
  return moment(dt).format(fmt);
}

export function getDate(dt) {
  return moment(dt).format("DD-MM-YYYY");
}

export function getDateTime(dt) {
  return moment(dt).format("DD-MM-YYYY HH:mm");
}

export function getDuration(dt, end) {
  let end_time = null;
  if (end == null) {
    end_time = moment();
  } else {
    end_time = moment(end);
  }
  const diff = moment.utc(end_time.diff(moment(dt)));
  const days = end_time.diff(moment(dt), "days");
  let response = "";
  if (days > 0) {
    response = days + " days ";
  }
  return response + diff.format("HH:mm");
}

export function hourFormat(value) {
  return moment(new Date(value)).format("HH:mm");
}

export function fromHourString(time, fmt = "00:00") {
  return moment(time)
    .set("hour", fmt.split(":")[0])
    .set("minute", fmt.split(":")[1])
    .toDate();
}

export function utc(value) {
  return moment.utc(value * 1000);
}

export function getDurationFromSeconds(seconds) {
  const dur = moment.utc(seconds * 1000);
  const days = dur.diff(moment.utc(0), "days");
  let response = "";
  if (days > 0) {
    response = days + " days ";
  }
  return response + dur.format("HH:mm");
}

export function getWeekdayName(weekday_number) {
  return [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ][weekday_number];
}

export function formatCurrencyEur(str) {
  if (str !== "" && str !== null) {
    return (
      "€ " +
      parseFloat(str).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        useGrouping: false,
      })
    );
  }
  return "";
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function includesOne(haystack, arr) {
  /**
   * @description determine if an array contains one or more items
   *              from another array.
   * @param {array} haystack the array to search.
   * @param {array} arr the array providing items to check for in the haystack.
   * @return {boolean} true|false if haystack contains at least one item
   *         from arr.
   */
  return arr.some(function (v) {
    return haystack.indexOf(v) >= 0;
  });
}

export function objectsDiff(original, modified) {
  if (Object.keys(original).length !== Object.keys(modified).length) {
    throw new Error("Objects must have the same number of keys");
  }
  const diff = {};
  for (const key in modified) {
    if (
      original[key] !== null &&
      modified[key] !== null &&
      typeof original[key] === "object" &&
      Array.isArray(original[key]) === false
    ) {
      // recursively compare objects
      const nested_diff = objectsDiff(original[key], modified[key]);
      if (Object.keys(nested_diff).length > 0) {
        diff[key] = modified[key];
      }
    } else if (modified[key] !== original[key]) {
      // compare values
      diff[key] = modified[key];
    }
  }
  return diff;
}

export async function defaultDetailLoad(url) {
  const data = await getAPIData(url);

  const response = { form: { initial: {}, data: {} } };
  Object.keys(data).forEach((field) => {
    if (field.startsWith("_")) {
      /** This is a workaround to separate functional fields from form fields
       *  The functional fields are prefixed with an underscore (_)
       *  and are added to the response object
       *  The form fields are added to the form object
       */
      // trim _ from fieldname
      response[field.substring(1)] = data[field];
    } else {
      response.form.initial[field] = data[field];
      response.form.data[field] = data[field];
    }
  });
  return response;
}

export async function getAPIData(url) {
  const { data, status } = await useCustomFetch(url);

  if (status.value === "success") {
    return data.value.results;
  } else {
    return [];
  }
}

export function createClonedCopy(val) {
  if (typeof val === "object") {
    return structuredClone(toRaw(val));
  } else {
    return val;
  }
}

// JS format string
/* eslint no-extend-native: ["error", { "exceptions": ["String"] }] */
export function $format(string, ...args) {
  // use replace to iterate over the string
  // select the match and check if the related argument is present
  // if yes, replace the match with the argument
  return string.replace(/{(\d+)}/g, function (match, index) {
    // check if the argument is present
    return typeof args[index] == "undefined" ? match : args[index];
  });
}

// usage: console.log('{0} is {1} years old and likes {2}'.format('John', 30,
// 'pizza'));

export function validateName(rule, value) {
  const errors = [];
  if (!/^[0-9a-zA-Z-_\s]*$/.test(value)) {
    errors.push(
      new Error(
        $format(
          "{0} must be alphanumeric characters, space, dash or underscore",
          rule.field,
        ),
      ),
    );
  }
  return errors;
}
