import { defineStore } from "pinia";

export const useAlertStore = defineStore("alert", {
  state: () => ({
    items: {}
  }),
  actions: {
    addAlert(alert) {
      if ("key" in alert) {
        this.items[alert.key] ={
          show: alert.show,
          message: alert.message,
          closeText: alert.closeText,
          handler: alert.handler
        };
      } else {
        console.error("Alert has no key:", alert)
      }
    },
  }
});
