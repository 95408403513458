import { useCustomFetch } from "~/hooks/useCustomFetch";

const user_base_url = "/api/user_core/user/";
const resend_confirmation_email = "/api/auth/registration/confirm/resend/";
const resend_acceptation_email = "/api/auth/registration/accept/resend/";

export default () => ({
  async resendConfirmationEmail({ email }) {
    const { error, status } = await useCustomFetch(resend_confirmation_email, {
      method: "POST",
      body: { email: email },
    });
    return { success: status.value === "success", error: error.value };
  },
  async resendAcceptationEmail({ email }) {
    const { error, status } = await useCustomFetch(resend_acceptation_email, {
      method: "POST",
      body: { email },
    });

    return { success: status.value === "success", error: error.value };
  },
  async acceptUserAccount({ userid }) {
    const url = user_base_url + `${userid}/`;

    const { error, status } = await useCustomFetch(url, {
      method: "PATCH",
      body: { activate: true },
    });

    return { success: status.value === "success", error: error.value };
  },
  async denyUserAccount({ userid }) {
    const url = user_base_url + `${userid}/`;

    const { error, status } = await useCustomFetch(url, {
      method: "PATCH",
      body: { activate: false },
    });

    return { success: status.value === "success", error: error.value };
  },
});
