import type { Ref } from "vue";
import { getTenantName } from "~/utils";

export function useJwtTokens<T>(): TokenRef<T> {

  const jwt_tokens = {
    tenant_name: getTenantName(),
    get access_token(): string {
      return localStorage.getItem(`${this.tenant_name}_access_token`) || "";
    },
    get refresh_token() {
      return localStorage.getItem(`${this.tenant_name}_refresh_token`) || "";
    },
    set access_token(token: string) {
      localStorage.setItem(`${this.tenant_name}_access_token`, token);
    },
    set refresh_token(token: string) {
      localStorage.setItem(`${this.tenant_name}_refresh_token`, token);
    },
    get su_access_token() {
      return localStorage.getItem(`su_${this.tenant_name}_access_token`) || "";
    },
    get su_refresh_token() {
      return localStorage.getItem(`su_${this.tenant_name}_refresh_token`) || "";
    },
    set su_access_token(token: string) {
      localStorage.setItem(`su_${this.tenant_name}_access_token`, token);
    },
    set su_refresh_token(token: string) {
      localStorage.setItem(`su_${this.tenant_name}_refresh_token`, token);
    },
    su_login: function() {
      this.tenant_name = getTenantName();
      localStorage.setItem(
        `su_${this.tenant_name}_refresh_token`,
        jwt_tokens.refresh_token
      );
      localStorage.setItem(
        `su_${this.tenant_name}_access_token`,
        jwt_tokens.access_token
      );
    },
    su_logout: function() {
      this.tenant_name = getTenantName();
      const su_access_token = localStorage.getItem(
        `su_${this.tenant_name}_access_token`
      );
      if (su_access_token) {
        localStorage.setItem(
          `${this.tenant_name}_access_token`,
          su_access_token
        );
      }
      const su_refresh_token = localStorage.getItem(
        `su_${this.tenant_name}_refresh_token`
      );
      if (su_refresh_token) {
        localStorage.setItem(
          `${this.tenant_name}_refresh_token`,
          su_refresh_token
        );
      }

      // remove su tokens
      localStorage.removeItem(`su_${this.tenant_name}_access_token`);
      localStorage.removeItem(`su_${this.tenant_name}_refresh_token`);
    },
    is_impostor: function() {
      this.tenant_name = getTenantName();
      const su_refresh = localStorage.getItem(
        `su_${this.tenant_name}_refresh_token`
      ) || "";
      return su_refresh !== "";
    },
    remove: function() {
      this.tenant_name = getTenantName();
      localStorage.removeItem(`${this.tenant_name}_access_token`);
      localStorage.removeItem(`${this.tenant_name}_refresh_token`);
      localStorage.removeItem(`su_${this.tenant_name}_access_token`);
      localStorage.removeItem(`su_${this.tenant_name}_refresh_token`);
    }
  };

  return {
    get value() {
      return {
        access: jwt_tokens.access_token,
        refresh: jwt_tokens.refresh_token
      };
    },
    set value(value: { access: string; refresh: string }) {
      jwt_tokens.access_token = value.access;
      jwt_tokens.refresh_token = value.refresh;
    },
    remove: jwt_tokens.remove,
    su_login: jwt_tokens.su_login,
    su_logout: jwt_tokens.su_logout,
    is_impostor: jwt_tokens.is_impostor
  } as TokenRef<T>;
}

export interface TokenRef<T> extends Ref<T> {
  remove: () => void;
  su_login: () => void;
  su_logout: () => void;
  is_impostor: () => boolean;
}
