import AuthRoutes from "./router.auth";
import EquipmentRoutes from "./router.equipment";
import LockerRoutes from "./router.lockers";
import type { RouterConfig } from "@nuxt/schema";
import UserRoutes from "./router.user";

const AdminPage = () => import("~/pages/admin/index.vue");
const AuthLoggedOut = () => import("~/pages/auth/loggedout.vue");
const EquipmentBookings = () => import("~/pages/machine/reservations.vue");
const IssueDetail = () => import("~/pages/issue/detail.vue");
const IssueList = () => import("~/pages/issue/list.vue");
const JobList = () => import("~/pages/job/list.vue");
const LoginPage = () => import("~/pages/auth/login.vue");
const ProblemDetail = () => import("~/pages/problem/detail.vue");
const WorkspaceList = () => import("~/pages/workspace/list.vue");

export default <RouterConfig>{
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: (_routes) => [
    ...AuthRoutes.routes,
    {
      name: "login",
      path: "/login",
      component: LoginPage,
      meta: {
        layout: "public-layout"
      }
    },
    ...EquipmentRoutes.routes,
    ...LockerRoutes.routes,
    ...UserRoutes.routes,
    {
      name: "logout",
      path: "/logout",
      component: AuthLoggedOut,
      meta: {
        layout: "public-layout"
      }
    },
    {
      name: "home",
      path: "/",
      redirect: { name: "machine_list" }
    },
    {
      path: "/machine/bookings",
      name: "machine_bookings",
      component: EquipmentBookings,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["machine.read"],
        crudMode: "read",
        contentMode: "bookings"
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/job/list",
      component: JobList,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["job.read"]
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/admin",
      name: "admin",
      component: AdminPage,
      props: { allowed_roles: ["is_staff", "is_superuser"] },
      meta: {
        layout: "authenticated-layout"
      }
    },

    /* OWNERS (previously workspace) */

    {
      path: "/workspace/list",
      component: WorkspaceList,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["workspace.read"],
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/issue/list",
      component: IssueList,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["deviceissue.read"],
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/issue/detail/:id",
      component: IssueDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["deviceissue.read"],
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/problem/detail/:id",
      component: ProblemDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["baseactivity.write"],
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
  ]
};
