import { useCustomFetch } from "~/hooks/useCustomFetch";

const url = "/api/reservations/reservation/";

export default () => ({
  /*
   * Get all machines from API
   */
  add(event) {
    return useCustomFetch(url, {
      method: "POST",
      body: {
        ...event,
      },
    });
  },
  all() {
    return useCustomFetch(`${url}?no_page=true`);
  },
  load(timeslot) {
    const _url =
      `${url}?no_page=true` +
      `&booked_after=${timeslot.start}` +
      `&booked_before=${timeslot.end}`;
    return useCustomFetch(_url);
  },
  update(event_id, event) {
    return useCustomFetch(url + event_id + "/", {
      method: "PATCH",
      body: event,
    });
  },
  delete(event_id) {
    return useCustomFetch(url + event_id + "/", {
      method: "DELETE",
    });
  },
});
