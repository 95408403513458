import { useCustomFetch } from "~/hooks/useCustomFetch";

const url = "/api/job_core/job/";

export default () => ({
  load(timeslot) {
    const _url =
      `${url}?no_page=true&` +
      `posted_after=${timeslot.start}&` +
      `posted_before=${timeslot.end}`;
    return useCustomFetch(_url);
  },
});
