const AuthConfirmResetPassword = () =>
  import("~/pages/auth/confirmation/reset.vue");
const AuthConfirmation = () => import("~/pages/auth/confirmation.vue");
const AuthInviteConfirmation = () =>
  import("~/pages/auth/confirmation/invite.vue");
const AuthSiteConfirmation = () => import("~/pages/auth/confirmation/site.vue");
const BadgeSuccess = () => import("~/pages/user/settings/badge/success.vue");
const EmailConfirm = () => import("~/pages/user/profile/email/confirm.vue");
const Privacy = () => import("~/pages/user/privacy.vue");
const ProfileEdit = () => import("~/pages/user/profile/edit.vue");
const SamlAuth = () => import("~/pages/auth/saml.vue");
const UserBadge = () => import('~/pages/user/settings/badge/index.vue');

export default {
  routes: [
    {
      name: "register-badge",
      path: "/user/settings/badge",
      component: UserBadge,
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      name: "badge_succes",
      path: "/user/settings/badge/success",
      component: BadgeSuccess,
      props: { allowed_permissions: ["job.read"] },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      name: "confirm_reset_password",
      path: "/auth/confirmation/reset",
      component: AuthConfirmResetPassword,
      meta: {
        layout: "public-layout"
      }
    },
    {
      name: "confirmation",
      path: "/auth/confirm",
      component: AuthConfirmation,
      meta: {
        layout: "public-layout"
      }
    },
    {
      name: "confirmation_site_registration",
      path: "/auth/confirmation/site",
      component: AuthSiteConfirmation,
      meta: {
        layout: "public-layout"
      }
    },
    {
      name: "confirmation_invite_registration",
      path: "/auth/confirmation/invite",
      component: AuthInviteConfirmation,
      meta: {
        layout: "public-layout"
      }
    },
    {
      path: "/auth/saml",
      component: SamlAuth,
      meta: {
        layout: "public-layout"
      }
    },
    {
      name: "profile_edit",
      path: "/user/profile/edit",
      component: ProfileEdit,
      props: { allowed_permissions: ["job.read"] },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      name: "profile_edit_email_confirm",
      path: "/user/profile/email/confirm/",
      component: EmailConfirm,
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/user/privacy/",
      name: "user_privacy",
      component: Privacy,
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/privacy-policy/",
      name: "privacy_policy",
      component: () => {
        window.location.href =
          "https://www.toolsquare.io/legal/platform-privacy-policy";
      },
    },
  ]
}
