import { useNuxtApp, useRouter } from "#app";
import { defineStore } from "pinia";
import { useAuthStore } from "~/stores/auth";
import { useConfigStore } from "~/stores/config";
import { useJwtTokens } from "~/hooks/useJwtTokens";

export const useUserStore = defineStore("user", {
  state: () => ({
    id: null,
    name: null,
    first_name: null,
    last_name: null,
    email: null,
    status: null,
    tenant_config: null,
    roles: [],
    permissions: {},
    new_email: null,
    has_new_email: false,
    has_badge: false,
    errors: {},
    impostor: false,
  }),
  getters: {
    get_errors(state) {
      return new Proxy(state.errors, {
        get: (target, prop) => {
          if (prop in target) {
            if (Array.isArray(target[prop])) {
              return target[prop].join(", ");
            }
            return target[prop];
          }
          return "";
        }
      });
    },
    get_non_field_errors(state) {
      return state.non_field_errors;
    }
  },
  actions: {
    async accept_privacy_policy({privacy_policy_accepted}) {
      const { $api } = useNuxtApp();

      const {error, status}
        = await $api.currentUser.accept_privacy_policy(
          {privacy_policy_accepted}
      );

      if (status.value === "success") {
        await this.refresh();
        return true;
      } else {
        this.errors = error.value.data.errors
        return false;
      }
    },
    async update(userdata) {
      this.id = userdata.id;
      this.name = userdata.first_name;
      this.first_name = userdata.first_name;
      this.last_name = userdata.last_name;
      this.has_badge = userdata.has_badge;
      this.has_new_email = userdata.has_new_email;
      this.new_email = userdata.new_email?.email;
      this.email = userdata.email;
      this.permissions = userdata.permissions;
      this.roles = userdata.roles;
      this.status = userdata.status;
      this.tenant_config = userdata.tenant_config;

      const alerts = useAlertStore();

      if (!this.has_badge) {
        alerts.addAlert({
          show: true,
          key: "register_badge",
          message:
            "You have no active badge. " +
            "Register a badge to start using a machine.",
          closeText: "Register now",
          handler: () => {
            const router = useRouter();
            router.push("/user/settings/badge");
          }
        });
      }
      if (this.has_new_email) {
        alerts.addAlert({
          show: true,
          key: "verify_email",
          message:
            "You have requested to change your email address. " +
            "Please verify your new email address.",
          closeText: "resend confirmation email",
          handler: () => {
            this.resend_change_emailaddress_confirmation_mail()
          }
        });
      }
      if (this.status === "privacy_policy_not_accepted") {
        const authStore = useAuthStore();
        authStore.code = "privacy_policy_not_accepted"
      }

      // update Tenant Config
      const config = useConfigStore();
      config.access_hours = userdata.config.access_hours;
      config.calendar_url = userdata.config.calendar_url;
      config.dashboard_url = userdata.config.dashboard_url;
      config.features = userdata.config.features;
    },
    async update_user_profile_data(data) {
      this.errors = {};
      const { $api } = useNuxtApp();
      const { error, status } =
        await $api.currentUser.updateUser(data);

      if (error.value) this.errors = error.value.data.errors
      return {error, status};
    },
    /**
     * Resend confirmation mail for email address change
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
    async resend_change_emailaddress_confirmation_mail(): Promise<void> {
      const { $api } = useNuxtApp();
      // Resend Confirmation Email
      await $api.currentUser
        .resend_change_emailaddress_confirmation_mail();
    },
    async active(): Promise<boolean> {
      if (this.status != "active") {
        // do not grant access to users with status different from "active"
        return false;
      }
      return true
    },
    async retrieve(): Promise<boolean> {
      /*
      * Retrieve the current user:
      * - if the user data is available, return true
      * - if the user data is not available,
      *   fetch the user data using the access token
      * - if the access token is not valid,
      *   refresh the access token and try again
      * */
      if (this.email != null) {
        return true;
      }
      return await this.refresh()
    },
    async refresh():Promise<boolean> {
      const { $api } = useNuxtApp();
      return await $api.currentUser.getCurrentUser()
        .then(async (data) => {
          // Hydrate user with response data
          await this.update(data.results);
          const tokens = useJwtTokens();
          this.impostor = tokens.is_impostor();
          return true;
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.data);
          return false;
        });
    },
    is_superuser() {
      if (this.name != null && "roles" in this) {
        return this.roles?.includes("is_superuser");
      }
      return false;
    },
    async is_lab_manager() {
      return this.roles?.includes("tenant_manager");
    },
    has_role(role) {
      return this.roles?.includes(role);
    },
    async cancelEmailAddressChange() {
      const { $api } = useNuxtApp();
      // Cancelling email address change
      const {status} = await $api.currentUser.cancel_change_email_address()

      if (status.value === "success") {
        this.has_new_email = false;
      }
    },
    async delete() {
      const { $api } = useNuxtApp();
      const { error, status } = await $api.currentUser.deleteUser();

      this.errors = error.value?.data.errors;

      return {error, status};
    },
    logout() {
      const router = useRouter();
      this.$reset();
      router.push("/logout");
    },
    can(permission:string, object:string) {
      if (Boolean(this.permissions) && (object in this.permissions)) {
        const o = this.permissions[object];
        if (permission in o) {
          return o[permission]; // True or False
        }
      }
      return false;
    }
  }
});
