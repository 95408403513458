import { defineStore } from "pinia";
import { getBaseURL } from "~/utils";
import { useNuxtApp } from "#app";

export const useConfigStore = defineStore("config", {
  state: () => ({
    user_registration: "invite",
    access_hours: {},
    calendar_url: null,
    dashboard_url: null,
    features: [] as string[]
  }),
  getters: {
    backend_url() {
      return getBaseURL();
    }
  },
  actions: {
    async updateConfig() {
      const { $api } = useNuxtApp();
      return await $api.config.get().then((response) => {
        this.user_registration = response.user_registration;
      }).catch(async e => {
        console.error(e);
        if (String(e.cause) === "TypeError: Failed to fetch") {
          const authStore = useAuthStore();
          authStore.errors = {
            non_field_errors: "You are offline"
          }
        }
      });
    },
    has_feature(feature:string) {
      return this.features.includes(feature);
    }
  }
});
