import { default as index1kcBTKlDMxMeta } from "/vercel/path0/src/pages/admin/index.vue?macro=true";
import { default as invite6FsHOOLnrhMeta } from "/vercel/path0/src/pages/auth/confirmation/invite.vue?macro=true";
import { default as resetd9wsnNUTqRMeta } from "/vercel/path0/src/pages/auth/confirmation/reset.vue?macro=true";
import { default as siteXioE3rtex6Meta } from "/vercel/path0/src/pages/auth/confirmation/site.vue?macro=true";
import { default as confirmationMRb1stlh9HMeta } from "/vercel/path0/src/pages/auth/confirmation.vue?macro=true";
import { default as loggedout7C759Bf4GSMeta } from "/vercel/path0/src/pages/auth/loggedout.vue?macro=true";
import { default as loginiDyrB7ID3PMeta } from "/vercel/path0/src/pages/auth/login.vue?macro=true";
import { default as successAi5ayTF2M0Meta } from "/vercel/path0/src/pages/auth/registration/success.vue?macro=true";
import { default as resetXK4TBSZ8dKMeta } from "/vercel/path0/src/pages/auth/reset.vue?macro=true";
import { default as samlsHat0n0izxMeta } from "/vercel/path0/src/pages/auth/saml.vue?macro=true";
import { default as indexkP7wmsbr11Meta } from "/vercel/path0/src/pages/index.vue?macro=true";
import { default as detailD1HIldzSkpMeta } from "/vercel/path0/src/pages/issue/detail.vue?macro=true";
import { default as listMXyocBg21JMeta } from "/vercel/path0/src/pages/issue/list.vue?macro=true";
import { default as listJDqh0014pjMeta } from "/vercel/path0/src/pages/job/list.vue?macro=true";
import { default as detailPtcGQjRxGoMeta } from "/vercel/path0/src/pages/locker/detail.vue?macro=true";
import { default as listVMf6bWoWnBMeta } from "/vercel/path0/src/pages/locker/list.vue?macro=true";
import { default as detail79GWNZnaunMeta } from "/vercel/path0/src/pages/machine/detail.vue?macro=true";
import { default as listYx8B5d5REDMeta } from "/vercel/path0/src/pages/machine/list.vue?macro=true";
import { default as reservationsqUwKF3ICaHMeta } from "/vercel/path0/src/pages/machine/reservations.vue?macro=true";
import { default as detailfrks6VNImBMeta } from "/vercel/path0/src/pages/machinefamily/detail.vue?macro=true";
import { default as listYkwGmFGvGUMeta } from "/vercel/path0/src/pages/machinefamily/list.vue?macro=true";
import { default as detail7iNcw5EhWJMeta } from "/vercel/path0/src/pages/problem/detail.vue?macro=true";
import { default as list1aMUo8Rmx2Meta } from "/vercel/path0/src/pages/staffmember/list.vue?macro=true";
import { default as detaila9LLUqUJYfMeta } from "/vercel/path0/src/pages/toolfamily/detail.vue?macro=true";
import { default as listY4mMtPX7gAMeta } from "/vercel/path0/src/pages/toolfamily/list.vue?macro=true";
import { default as detailwhOMj9ACiVMeta } from "/vercel/path0/src/pages/user/detail.vue?macro=true";
import { default as list4jMiysve8hMeta } from "/vercel/path0/src/pages/user/list.vue?macro=true";
import { default as privacyUyLP8BqGfiMeta } from "/vercel/path0/src/pages/user/privacy.vue?macro=true";
import { default as editm06yFQ2gKpMeta } from "/vercel/path0/src/pages/user/profile/edit.vue?macro=true";
import { default as confirmmrd4rTrXPVMeta } from "/vercel/path0/src/pages/user/profile/email/confirm.vue?macro=true";
import { default as indexTmJO9Q8gHGMeta } from "/vercel/path0/src/pages/user/settings/badge/index.vue?macro=true";
import { default as successy45YkkUCxdMeta } from "/vercel/path0/src/pages/user/settings/badge/success.vue?macro=true";
import { default as detail8BNsNeLL1fMeta } from "/vercel/path0/src/pages/usergroup/detail.vue?macro=true";
import { default as listEayiQLEYV0Meta } from "/vercel/path0/src/pages/usergroup/list.vue?macro=true";
import { default as listSieXBRPhtNMeta } from "/vercel/path0/src/pages/workfloor/list.vue?macro=true";
import { default as listC9qdhZ73dZMeta } from "/vercel/path0/src/pages/workspace/list.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    component: () => import("/vercel/path0/src/pages/admin/index.vue")
  },
  {
    name: "auth-confirmation",
    path: "/auth/confirmation",
    component: () => import("/vercel/path0/src/pages/auth/confirmation.vue"),
    children: [
  {
    name: "auth-confirmation-invite",
    path: "invite",
    component: () => import("/vercel/path0/src/pages/auth/confirmation/invite.vue")
  },
  {
    name: "auth-confirmation-reset",
    path: "reset",
    component: () => import("/vercel/path0/src/pages/auth/confirmation/reset.vue")
  },
  {
    name: "auth-confirmation-site",
    path: "site",
    component: () => import("/vercel/path0/src/pages/auth/confirmation/site.vue")
  }
]
  },
  {
    name: "auth-loggedout",
    path: "/auth/loggedout",
    component: () => import("/vercel/path0/src/pages/auth/loggedout.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/vercel/path0/src/pages/auth/login.vue")
  },
  {
    name: "auth-registration-success",
    path: "/auth/registration/success",
    component: () => import("/vercel/path0/src/pages/auth/registration/success.vue")
  },
  {
    name: "auth-reset",
    path: "/auth/reset",
    component: () => import("/vercel/path0/src/pages/auth/reset.vue")
  },
  {
    name: "auth-saml",
    path: "/auth/saml",
    component: () => import("/vercel/path0/src/pages/auth/saml.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/src/pages/index.vue")
  },
  {
    name: "issue-detail",
    path: "/issue/detail",
    component: () => import("/vercel/path0/src/pages/issue/detail.vue")
  },
  {
    name: "issue-list",
    path: "/issue/list",
    component: () => import("/vercel/path0/src/pages/issue/list.vue")
  },
  {
    name: "job-list",
    path: "/job/list",
    component: () => import("/vercel/path0/src/pages/job/list.vue")
  },
  {
    name: "locker-detail",
    path: "/locker/detail",
    component: () => import("/vercel/path0/src/pages/locker/detail.vue")
  },
  {
    name: "locker-list",
    path: "/locker/list",
    component: () => import("/vercel/path0/src/pages/locker/list.vue")
  },
  {
    name: "machine-detail",
    path: "/machine/detail",
    component: () => import("/vercel/path0/src/pages/machine/detail.vue")
  },
  {
    name: "machine-list",
    path: "/machine/list",
    component: () => import("/vercel/path0/src/pages/machine/list.vue")
  },
  {
    name: "machine-reservations",
    path: "/machine/reservations",
    component: () => import("/vercel/path0/src/pages/machine/reservations.vue")
  },
  {
    name: "machinefamily-detail",
    path: "/machinefamily/detail",
    component: () => import("/vercel/path0/src/pages/machinefamily/detail.vue")
  },
  {
    name: "machinefamily-list",
    path: "/machinefamily/list",
    component: () => import("/vercel/path0/src/pages/machinefamily/list.vue")
  },
  {
    name: "problem-detail",
    path: "/problem/detail",
    component: () => import("/vercel/path0/src/pages/problem/detail.vue")
  },
  {
    name: "staffmember-list",
    path: "/staffmember/list",
    component: () => import("/vercel/path0/src/pages/staffmember/list.vue")
  },
  {
    name: "toolfamily-detail",
    path: "/toolfamily/detail",
    component: () => import("/vercel/path0/src/pages/toolfamily/detail.vue")
  },
  {
    name: "toolfamily-list",
    path: "/toolfamily/list",
    component: () => import("/vercel/path0/src/pages/toolfamily/list.vue")
  },
  {
    name: "user-detail",
    path: "/user/detail",
    component: () => import("/vercel/path0/src/pages/user/detail.vue")
  },
  {
    name: "user-list",
    path: "/user/list",
    component: () => import("/vercel/path0/src/pages/user/list.vue")
  },
  {
    name: "user-privacy",
    path: "/user/privacy",
    component: () => import("/vercel/path0/src/pages/user/privacy.vue")
  },
  {
    name: "user-profile-edit",
    path: "/user/profile/edit",
    component: () => import("/vercel/path0/src/pages/user/profile/edit.vue")
  },
  {
    name: "user-profile-email-confirm",
    path: "/user/profile/email/confirm",
    component: () => import("/vercel/path0/src/pages/user/profile/email/confirm.vue")
  },
  {
    name: "user-settings-badge",
    path: "/user/settings/badge",
    component: () => import("/vercel/path0/src/pages/user/settings/badge/index.vue")
  },
  {
    name: "user-settings-badge-success",
    path: "/user/settings/badge/success",
    component: () => import("/vercel/path0/src/pages/user/settings/badge/success.vue")
  },
  {
    name: "usergroup-detail",
    path: "/usergroup/detail",
    component: () => import("/vercel/path0/src/pages/usergroup/detail.vue")
  },
  {
    name: "usergroup-list",
    path: "/usergroup/list",
    component: () => import("/vercel/path0/src/pages/usergroup/list.vue")
  },
  {
    name: "workfloor-list",
    path: "/workfloor/list",
    component: () => import("/vercel/path0/src/pages/workfloor/list.vue")
  },
  {
    name: "workspace-list",
    path: "/workspace/list",
    component: () => import("/vercel/path0/src/pages/workspace/list.vue")
  }
]