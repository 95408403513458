import { $fetch } from "ofetch";
import { getBaseURL } from "~/utils.js";
import { useCustomFetch } from "~/hooks/useCustomFetch";
import { useFetch } from "#app";

const auth_url = "/api/auth";
const register_url = "/api/auth/register/";
// Login with username and password
const login_url = "/api/login";
const reset_password_url = "/api/auth/reset_password/";
const confirm_registration_url = "/api/auth/registration/confirm/";
const accept_registration_url = "/api/auth/registration/accept/";
const confirm_reset_password_url = "/api/auth/confirm_reset_password/";
const resend_account_confirmation_url = "api/auth/registration/confirm/resend/";

export default () => ({
  async authenticate({ email }) {
    return useFetch(getBaseURL() + auth_url + "/", {
      method: "POST",
      body: { email },
    });
  },
  register({
    first_name,
    last_name,
    email,
    password1,
    password2,
    privacy_policy_accepted,
  }) {
    return useFetch(register_url, {
      baseURL: getBaseURL(),
      method: "POST",
      body: {
        first_name,
        last_name,
        email,
        password1,
        password2,
        privacy_policy_accepted,
      },
    });
  },
  confirm_registration({ key }) {
    return useFetch(confirm_registration_url, {
      baseURL: getBaseURL(),
      method: "POST",
      body: { key },
    });
  },
  accept_registration_with_password({
    password1,
    password2,
    privacy_policy_accepted,
    key,
  }) {
    return useFetch(accept_registration_url, {
      baseURL: getBaseURL(),
      method: "POST",
      body: {
        password1,
        password2,
        privacy_policy_accepted,
        key,
      },
    });
  },
  login({ email, password }) {
    return $fetch(login_url + "/", {
      baseURL: getBaseURL(),
      method: "POST",
      body: { email, password },
    });
  },
  async reset({ email }) {
    return useFetch(reset_password_url, {
      baseURL: getBaseURL(),
      method: "POST",
      body: { email },
    });
  },
  confirm_reset_password({ key, password1, password2 }) {
    return useFetch(confirm_reset_password_url, {
      baseURL: getBaseURL(),
      method: "POST",
      body: { key, password1, password2 },
    });
  },
  saml({ key }) {
    return useFetch(login_url + "/saml/", {
      baseURL: getBaseURL(),
      method: "POST",
      body: { key },
    });
  },

  su_login({ id }) {
    return useCustomFetch(login_url + "/su/", {
      baseURL: getBaseURL(),
      method: "POST",
      body: { id: id },
    });
  },
  resend_account_creation_confirmation_email(data) {
    return useFetch(resend_account_confirmation_url, {
      baseURL: getBaseURL(),
      method: "POST",
      body: data,
    });
  },
});
