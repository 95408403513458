import { type TokenRef, useJwtTokens } from "~/hooks/useJwtTokens";
import Auth from "~/api/auth";
import Config from "~/api/config";
import CurrentUser from "~/api/current-user";
import Issues from "~/api/issues";
import Jobs from "~/api/jobs";
import Machines from "~/api/machines";
import Reservations from "~/api/reservations";
import User from "~/api/user";

import UserGroup from "~/api/user-group";
import { getBaseURL } from "~/utils";
import { useApiUrl } from "~/hooks/useApiUrl";

const IGNORED_API_PATHS = [
  "/api/login/",
  "/auth/login/",
  "/auth/logout/",
  "/auth/refresh/",
  "/api/token/refresh/"
];

export const getFetch = () => {

  const config = useRuntimeConfig();
  const tokensRefreshUrl = useApiUrl("tokensRefresh");
  const userAuth: TokenRef<Record<string, string>> = useJwtTokens();

  type TokenPair = {
    access: string;
    refresh: string;
  };

  return $fetch.create({
    baseURL: getBaseURL(),
    retryStatusCodes: [401],
    retry: 1,
    headers: {
      "Content-Type": "application/json",
    },
    onRequest({ options }) {
      if (userAuth.value?.access) {
        options.headers = {
          ...options.headers,
          "Authorization": `Bearer ${userAuth.value?.access}`
          // "Authorization": `JWT ${userAuth.value?.access}`
        };
      }
    },
    async onResponseError({ response }) {
      const relative_url = response.url.replace(config.public.API_BASE_URL, "");
      if (
        response.status === 401 &&
        !IGNORED_API_PATHS.includes(relative_url) &&
        userAuth.value.refresh) {
        await $fetch<TokenPair>(tokensRefreshUrl, {
          baseURL: getBaseURL(),
          method: "POST",
          body: {
            refresh: userAuth.value?.refresh
          }
        }).then(
          (response) => {
            userAuth.value = {
              access: response.access,
              refresh: response.refresh
            };
            return response;
          }
        ).catch((error) => {
          console.error(error)
          userAuth.remove();
          return false;
        });
      }
    }
  })
}

export default () => {

  const fetch = getFetch();

  return {
    auth: Auth(fetch),
    config: Config(fetch),
    machines: Machines(fetch),
    reservations: Reservations(fetch),
    issues: Issues(fetch),
    jobs: Jobs(fetch),
    currentUser: CurrentUser(fetch),
    user: User(fetch),
    userGroup: UserGroup(fetch)
  };
};
