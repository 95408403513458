const LockerDetail = () => import("~/pages/locker/detail.vue");
const LockerList = () => import("~/pages/locker/list.vue");
const ToolFamilyDetail = () => import("~/pages/toolfamily/detail.vue");
const ToolFamilyList = () => import("~/pages/toolfamily/list.vue");

export default {
  routes: [
    {
      path: "/locker/list",
      name: "locker_list",
      component: LockerList,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["locker.read"],
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/locker/detail/:id",
      component: LockerDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["locker.read"],
        crudMode: "read",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/locker/detail/:id/edit",
      component: LockerDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["locker.update"],
        crudMode: "update",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/locker/access/:id",
      component: LockerDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["locker.read"],
        crudMode: "read",
        contentMode: "access",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/toolfamily/list",
      component: ToolFamilyList,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["toolfamily.read"],
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/toolfamily/detail/:id",
      component: ToolFamilyDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["toolfamily.read"],
        crudMode: "read",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/toolfamily/detail/:id/edit",
      component: ToolFamilyDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["toolfamily.write"],
        crudMode: "update",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    {
      path: "/toolfamily/access/:id",
      component: ToolFamilyDetail,
      props: {
        allowed_roles: ["is_staff", "is_superuser"],
        allowed_permissions: ["toolfamily.read"],
        crudMode: "read",
        contentMode: "access",
      },
      meta: {
        layout: "authenticated-layout"
      }
    },
    ]
};
